import { API_HOST } from './site'

export const API_PATH = function(api_endpoint) {
  return `${API_HOST}${api_endpoint}`
}

// Auth
export const API_SIGNIN = '/auth/users/sign_in'
export const API_SIGNUP = '/auth/users'
export const API_PROFILE_PASSWORD = '/auth/users/password'
export const API_FORGOT_PASSWORD = '/auth/users/forgot'
export const API_RESET_PASSWORD = '/auth/users/reset'

// Users
export const API_USERS = '/users'
export const API_FILTERED_USERS = '/users/filtered'
export const API_SEARCH_FILTERED_USERS = '/users/search_filtered'

//Profile
export const API_MY_ROLES = '/users/my_roles'
export const API_PROFILE_PIC = '/users/upload'

// Tasks
export const API_TASKS = '/tasks'
export const API_TASK = '/tasks/:task_id'
export const API_TASK_SHOW = '/tasks/:task_id?single=true'
export const API_MY_TASKS = '/tasks/my_tasks'
// export const API_TASKS_CREATE = '/tasks'
// export const API_TASKS_CREATE = '/tasks'
export const API_TASKS_IMPORT = '/task_importer/import'
export const API_TASKS_PREVIOUS_DATA = '/tasks/:task_id/files_comments.json';
export const API_TASKS_CALL_TO_ACTIONS = '/tasks/call_to_actions'
export const API_TASK_COMMENTS = "/tasks/:task_id/task_comments"
export const API_TASK_RULES = "/tasks/:task_id/task_rules"
export const API_TASK_NIGHTLY_REPORT = "/tasks/:task_id/nightly_report"
export const API_TASK_UPDATE_CHECKLIST = "/tasks/:task_id/project_task_checklists/:id"
export const API_TASK_ADD_CHECKLIST = "/tasks/:task_id/project_task_checklists"
export const API_TASK_PASS_BATON = "/tasks/:task_id/pass_baton"
export const API_TASK_UPDATE_COUNT = '/tasks/:task_id/update_page_count'

// Task Requests
export const API_TASK_REQUESTS = '/task_requests'
export const API_MANAGE_TASK_REQUEST_APPROVE = '/manage/task_requests/:task_request_id/approve'
export const API_MANAGE_TASK_REQUEST_DECLINE = '/manage/task_requests/:task_request_id/decline'
export const API_MANAGE_TASK_REQUEST_BULK_APPROVE = '/manage/task_requests/bulk_approve'

// Knowledge Base
export const API_KNOWLEDGE_BASES = '/knowledge_bases'
export const API_KNOWLEDGE_BASE = '/knowledge_bases/:id'
export const API_KNOWLEDGE_BASES_FOR_PAGE = '/knowledge_bases/for_page'

// Report Presets
export const API_REPORT_PRESETS = '/report_presets'
export const API_REPORT_PRESET = '/report_presets/:id'

// Manage
export const API_MANAGE_USERS = '/manage/users'
export const API_MANAGE_CATEGORIES = '/manage/categories'
export const API_MANAGE_FIRMS = '/manage/firms'
export const API_MANAGE_ACCOUNTS = '/manage/accounts'
export const API_MANAGE_LOCATIONS = '/manage/locations'
export const API_MANAGE_RECURRING_TASKS = '/manage/recurring_tasks'
export const API_MANAGE_TASK_REQUESTS = '/manage/task_requests'
export const API_MANAGE_FIRM_PREFERENCES = '/manage/firm_preferences/:firm_id.json'

// References
export const API_REFERENCES = '/tasks/references'

// Notifications
export const API_NOTIFICATIONS = '/notifications'
export const API_UNREAD_NOTIFICATIONS = '/notifications/unread'
export const API_MARK_AS_SEEN_NOTIFICATIONS = '/notifications/mark_as_seen'


// Chat
// Users
export const API_CHAT_USERS = '/chat_channels/users'
export const API_CHAT_MESSAGES = '/chat_channels/messages'
export const API_CHAT_CREATE_CHANNEL = '/chat_channels/create_channel'
export const API_CHAT_CHANNEL_USERS = '/chat_channels/:chat_channel_id/chat_channel_users'

// Projects
export const API_MANAGE_PROJECTS = '/manage/projects'
export const API_MANAGE_ACCOUNT_CREATE_PROJECTS = '/manage/projects/:firm_id/create_account'
export const API_MANAGE_PROJECTS_DELETE_ACCOUNT = '/manage/projects/delete_account'
export const API_MANAGE_PROJECTS_ADD_LOCATION = '/manage/projects/:account_id/create_location'
export const API_MANAGE_PROJECTS_DELETE_LOCATION = '/manage/projects/delete_location'
export const API_MANAGE_PROJECTS_ADD_TASK = '/manage/projects/:category_id/create_task'
export const API_MANAGE_PROJECTS_DELETE_TASK = '/manage/projects/delete_task'
export const API_MANAGE_PROJECTS_UPDATE_TASK = '/manage/projects/update_task'
export const API_MANAGE_PROJECTS_CATEGORIES = '/manage/projects/:firm_id/categories'
export const API_MANAGE_PROJECTS_UPDATE_TASKS_AND_ASSOCIATIONS = '/manage/projects/update_tasks_associations'

export const API_MANAGE_PROJECTS_UPDATE_TASK_DESCRIPTION = '/manage/projects/:firm_id/update_task_description'
export const API_MANAGE_PROJECTS_UPDATE_PROJECT_TASK = '/manage/projects/:firm_id/update_project_task'

export const API_MANAGE_PROJECTS_SET_WORK_AS_ONE    = '/manage/projects/:firm_id/set_work_as_one'
export const API_MANAGE_PROJECTS_SET_FREQUENCY      = '/manage/projects/:firm_id/set_frequency'
export const API_MANAGE_PROJECTS_SET_FREQUENCY_WEEK = '/manage/projects/:firm_id/set_frequency_week'
export const API_MANAGE_PROJECTS_SET_USERS          = '/manage/projects/:firm_id/set_users'
export const API_MANAGE_PROJECTS_SET_DEPARTMENT     = '/manage/projects/:firm_id/set_department'
export const API_MANAGE_PROJECTS_SET_EMAIL_RECIPIENTS = '/manage/projects/:firm_id/set_email_recipients'
export const API_MANAGE_PROJECTS_SET_EMAIL_SENDERS    = '/manage/projects/:firm_id/set_email_senders'
export const API_MANAGE_PROJECTS_SET_TAGS           = '/manage/projects/:firm_id/set_tags'

export const API_MANAGE_PROJECTS_ADD_RULE           = '/manage/projects/:firm_id/add_rule'
export const API_MANAGE_PROJECTS_DELETE_RULE        = '/manage/projects/delete_rule'

export const API_MANAGE_PROJECTS_SET_FIRM_WIDE    = '/manage/projects/:firm_id/set_firm_wide'
export const API_MANAGE_PROJECTS_UNSET_FIRM_WIDE  = '/manage/projects/:firm_id/unset_firm_wide'

export const API_MANAGE_PROJECTS_SET_ACCOUNT_WIDE    = '/manage/projects/:firm_id/set_account_wide'
export const API_MANAGE_PROJECTS_UNSET_ACCOUNT_WIDE  = '/manage/projects/:firm_id/unset_account_wide'

export const API_MANAGE_PROJECTS_SET_LOCATION_WIDE    = '/manage/projects/:firm_id/set_location_wide'
export const API_MANAGE_PROJECTS_UNSET_LOCATION_WIDE  = '/manage/projects/:firm_id/unset_location_wide'

// Export
export const EXPORT_TASKS_BY_FIRM = '/exports/tasks_by_firm'
export const EXPORT_TASKS_BY_FIRM_V2 = '/exports/tasks_by_firm_v2'
export const EXPORT_ACCOUNTING_PROJECT_TASKS = '/accounting_project_tasks/export'

//REPORTS
export const USER_WEEKLY_SUMMARY_REPORTS = '/reports/user_weekly_summary'
export const USER_DATA_REPORTS = '/reports/user_data'
export const COMPARATIVE_REPORTS = '/reports/comparative'
export const USER_PROFILE_REPORT = '/reports/user_profile'
export const TASK_WEEKLY_COUNT_REPORTS = '/reports/task_weekly_count'
export const DAILY_TASK_REPORTS = '/reports/daily_task_reports'
export const WEEKLY_TASK_REPORTS = '/reports/weekly_task_reports'
export const TASK_REQUEST_REPORTS = '/reports/task_requests'

export const WEEKLY_FIRM_REPORTS = '/reports/weekly_firm_reports'
export const FIRM_TASKS_REPORTS = '/reports/firm_tasks_reports'
export const ACCOUNT_TE_TV_REPORTS = '/reports/account_te_tv_reports'

export const DAILY_QUEUES_COUNT_REPORT = '/reports/daily_queues_count'
export const FIRMS_WEEKLY_AP_TRANSACTION_COUNT_REPORT = '/reports/firms_weekly_ap_transaction_count'
export const DATA_REPORTS = '/reports/data_reports'
export const DATA_DAILY_HOURS_REPORT = '/reports/data_daily_hours'
export const EXPORT_GOOGLE_SHEET = '/exports/export_to_sheet'
export const TIME_VARIANCE_REPORT = '/reports/time_variance'
export const SCOPE_VARIANCE_REPORT = '/reports/scope_variance'

export const ACCOUNTING_TIME_VARIANCE_REPORT = '/reports/accounting_time_variance'

export const REPORTS_ASSIGNED_USERS = '/reports/assigned_users'
export const REPORTS_ASSIGN_USER = '/reports/assign_user'
export const REPORTS_UNASSIGN_USER = '/reports/unassign_user'

// Impersonate
export const IMPERSONATE_USER = '/users/impersonate'
export const STOP_IMPERSONATE = '/users/stop_impersonate'

// Task Categories
export const TASK_CATEGORIES = '/task_categories'
export const TASK_CATEGORY = '/task_categories/:id'
export const ACCOUNTING_DATA = '/task_categories/accounting_data'

// Accounting Project Task
export const ACCOUNTING_PROJECT_TASKS = '/accounting_project_tasks'
export const ACCOUNTING_PROJECT_TASK = '/accounting_project_tasks/:id'
export const ACCOUNTING_TASK_REFERENCES = '/accounting_project_task_references'
export const ACCOUNTING_TASK_REFERENCE = '/accounting_project_task_references/:id'
export const ACCOUNTING_TASK_REFERENCE_VERSIONS = '/accounting_project_task_reference_versions'
export const ACCOUNTING_PROJECT_TASKS_BY_ORG = '/accounting_project_tasks/filter_by_organization'

export const ACCOUNTING_TASK_ACKNOWLEDGE = '/accounting_project_task_references/acknowledge'
export const ACCOUNTING_TASK_NEEDS_ACKNOWLEDGEMENT = '/accounting_project_task_references/needs_acknowledgement'
export const ACCOUNTING_PROJECT_TASK_ARCHIVE = '/accounting_project_tasks/:id/archive_task'

export const ACCOUNTING_PROJECT_TASK_RESET_PH = '/accounting_project_tasks/:id/task_reset_ph'
export const ACCOUNTING_PROJECT_TASK_RESET_US = '/accounting_project_tasks/:id/task_reset_us'

// Dashboard
export const FIRM_DASHBOARDS_FIRM_DATA = '/manage/firm_dashboards/firm_data'
export const FIRM_DASHBOARDS_CHART_DATA = '/manage/firm_dashboards/chart_data'

// REFERENCES

export const REFERENCES_CATEGORIES = '/references/categories'
export const REFERENCES_TASK_CATEGORIES = '/references/task_categories'
export const REFERENCES_BASIC_ORGANIZATIONS = '/references/basic_organizations'
export const REFERENCES_ORGANIZATIONS = '/references/organizations'
export const REFERENCES_ROLES = '/references/roles'
export const REFERENCES_MYSELF = '/references/myself'
export const REFERENCES_CONNECTED_USERS = '/references/connected_users'
export const REFERENCES_STUCK_OPTIONS = '/references/stuck_options'
export const REFERENCES_DEPARTMENTS = '/references/departments'
export const REFERENCES_FIRM_USERS = '/references/firm_users'
export const REFERENCES_FIRM_DATA = '/references/firm_data'
