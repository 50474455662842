export const SITE_NAME = 'Frittata';

// export const API_HOST = 'https://api.billebot.com/'
// export const API_HOST = 'https://api.frittata.app'

// export const API_HOST = 'https://edgeapi.frittata.app';
export const API_HOST = 'https://apiv1.frittata.app';
// export const API_HOST = 'https://api1.frittata.app';
// export const API_HOST = 'https://stagingapi.frittata.app';
// export const API_HOST =
//   'http://stg-frittata-api-alb-447530134.us-east-1.elb.amazonaws.com/';
// export const API_HOST = 'https://egglinkstaging.frittata.app';

// export const API_HOST = 'http://localhost:3000';
// export const API_HOST = 'https://notamazonserver.com/

// export const WS_HOST = 'ws://localhost:3000/'
// export const WS_HOST = 'wss://api.billebot.com/'
// export const WS_HOST = 'wss://api.billebot.com/'
// export const WS_HOST = 'wss://apiws.frittata.app/';
// export const WS_HOST = 'wss://api.frittata.app/'
export const WS_HOST = 'wss://prod-frittata-cache-001.prod-frittata-cache.5pequw.use1.cache.amazonaws.com/';